







































































































import ClickOutside from '@/directives/clickOutside';
import {Component, Mixins, Prop, Vue, Watch} from 'vue-property-decorator';
import Vuelidate from 'vuelidate';
import {maxLength, required} from 'vuelidate/lib/validators';
import InputTag from 'vue-input-tag';
import DropDown from '@/components/DropDown.vue';
import DatePicker from '@/components/DatePicker.vue';
import moment from 'moment';
import {CategoryInterface} from '@/types/CategoryInterface';
import {ModelMinDataInterface} from '@/types/userDataInterface';
import {ShortVideoInterface} from '@/types/videoInterface';
import Loader from '@/components/Loader.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import {vxm} from '@/store';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';
import {GendersEnum} from '@/types/enums/GendersEnum';
import {PreferencesEnum} from '@/types/enums/PreferencesEnum';

Vue.use(Vuelidate);
Vue.use(InputTag);

const defaultForm: ShortVideoInterface = {
  type: 'recent',
  price: 0,
  name: '',
  categories: [],
  description: '',
  twitterText: '',
  tags: [],
  performer: [],
  isSchedule: false,
  schedule: '',
  status: 'inactive',
  imageThumbPath: null as any,
  imageFullPath: null as any,
  sort: 0,
  isSaleVideo: false,
  file: {
    image: '',
    fileFullPath: null as any,
  },
  vidType: 'vids',
  sexualOrientation: GendersEnum.MALE,
  sexualPreference: PreferencesEnum.GAY,
};
const inRange = (value) => value >= 1.99 && value <= 49.99;

@Component({
  directives: {
    ClickOutside,
  },
  components: {
    InputTag,
    DropDown,
    DatePicker,
    Loader,
    ProgressBar,
  },
  validations: {
    form: {
      name: {required},
      price: {inRange},
      twitterText: {
        maxLength: maxLength(280),
      },
    },
  },
})
export default class VideoForm extends Mixins(MomentDateMixin, DefaultAvatarMixin) {
  showPerformerHint = false;
  showTagsHint = false;
  timerId; // current id for setTimeout func, using to execute it
  performerSearchKey = '';
  tagsSearchKey = '';
  scheduleTime = moment().format('HH:00');
  scheduleDate = moment().format('YYYY-MM-DD');
  timeZone = moment()
    .format('ZZ')
    .replace('+0', '');
  showScheduleDD = false;
  minDate = moment().format('YYYY-MM-DD');
  types = ['vids', 'vids-plus', 'clips'];
  tags = [];
  performers: ModelMinDataInterface[] = [];
  @Prop(Boolean) readonly isEdit!: boolean;
  @Prop({default: () => []}) readonly categories!: CategoryInterface[];
  @Prop({default: defaultForm}) readonly value!: ShortVideoInterface;
  @Prop({default: () => []}) readonly coAuthorship!: {name: string; _id: string; username: string}[];
  authors = this.coAuthorship;
  genders = GendersEnum;
  preferences = PreferencesEnum;

  get videoActive() {
    return this.form.status === 'active';
  }

  set videoActive(val) {
    if (val) {
      this.form.status = 'active';
    } else {
      this.form.status = 'inactive';
    }
  }

  get form() {
    return this.value;
  }

  set form(value) {
    this.$emit('input', value);
  }
  @Watch('$v.form.$invalid') onFormError() {
    this.$emit('errors', this.$v.form.$invalid);
  }

  @Watch('form.isSchedule') onScheduleChange() {
    this.form.type = this.form.isSchedule ? 'upcoming' : 'recent';
    this.form.schedule = moment(this.scheduleDate + ' ' + this.scheduleTime).format();
  }

  @Watch('scheduleDate') onScheduleDateChange() {
    this.showScheduleDD = false;
    if (
      moment().isSame(this.scheduleDate, 'date') &&
      moment(this.scheduleDate + ' ' + this.scheduleTime).hour() <= moment().hour()
    ) {
      this.scheduleTime = this.timeFormat(moment().hour() + 1);
    }
    this.form.schedule = moment(this.scheduleDate + ' ' + this.scheduleTime).format();
  }

  @Watch('scheduleTime') onScheduleTimeChange() {
    this.form.schedule = moment(this.scheduleDate + ' ' + this.scheduleTime).format();
  }

  @Watch('performerSearchKey') onPerformerSearchKeyChanged() {
    this.showPerformerHint = this.performerSearchKey.length >= 2;
    if (this.showPerformerHint) {
      const keyword = this.performerSearchKey;
      this.searchTimer(() => {
        vxm.fan
          .performersMinInfo({keyword: keyword})
          .then((res) => {
            this.performers = res.data.filter((user) => {
              return (
                user._id !== vxm.user.data._id &&
                this.addedPerformers.findIndex((author) => author._id === user._id) === -1
              );
            });
          })
          .catch((error) => {
            return error;
          });
      });
    }
  }

  @Watch('form.vidType') onVidTypeChanged() {
    if (this.form.vidType === 'clips') {
      delete this.form.isSaleVideo;
    } else {
      this.form.isSaleVideo = this.form.vidType === 'vids-plus';
    }
  }

  @Watch('tagsSearchKey') onTagsSearchKeyChanged() {
    if (this.tagsSearchKey.length >= 1) {
      if (this.tagsSearchKey.search('#') !== -1) {
        this.tagsSearchKey = this.tagsSearchKey.substring(0, this.tagsSearchKey.length - 1);
      }
      this.searchTimer(() => {
        vxm.fan
          .getTags(this.tagsSearchKey)
          .then((res) => {
            this.tags = res.data.filter((tag) => {
              return !this.form.tags.includes(tag);
            });
            this.showTagsHint = !!this.tags.length;
          })
          .catch((error) => {
            return error;
          });
      });
      if (this.tagsSearchKey.search(' ') !== -1) {
        this.addTag(this.tagsSearchKey.trim());
      }
    }
  }

  get addedPerformers() {
    return this.authors;
  }

  set addedPerformers(newVal) {
    this.authors = newVal;
  }

  mounted() {
    if (this.value) {
      this.form = {...this.form, ...this.value};
    }
    if (this.isEdit) {
      this.types = this.form.vidType !== 'clips' ? ['vids', 'vids-plus'] : ['clips'];
    }
    if (this.form.schedule) {
      this.scheduleTime = moment(this.form.schedule).format('HH:00');
      this.scheduleDate = moment(this.form.schedule).format('YYYY-MM-DD');
    }
  }

  timeFormat(time): string {
    return (time < 10 ? '0' : '') + time + ':00';
  }

  timeFormatUS(time): string {
    return time <= 12 ? time + ' am' : time - 12 + ' pm';
  }

  addTag(tag) {
    clearTimeout(this.timerId);
    this.showTagsHint = false;
    if (!this.form.tags.includes(tag)) {
      this.form.tags.push(tag);
    }
    this.tagsSearchKey = '';
  }

  removeTag(index) {
    this.form.tags.splice(index, 1);
  }

  addPerformer(performer) {
    this.showPerformerHint = false;
    this.form.performer.push(performer._id);
    this.addedPerformers.push({_id: performer._id, name: performer.name, username: performer.username});
    this.performerSearchKey = '';
  }

  removePerformer(id, index) {
    this.form.performer = this.form.performer.filter((ids) => ids !== id);
    this.addedPerformers.splice(index, 1);
  }

  searchTimer(funcToExecute: Function) {
    clearTimeout(this.timerId);
    this.timerId = setTimeout(() => {
      funcToExecute();
    }, 1000);
  }
  isAvailableTime(time): boolean {
    return moment().isAfter(this.scheduleDate, 'date')
      ? false
      : moment().isSame(this.scheduleDate, 'date')
      ? moment().hour() < time
      : true;
  }
}
